<template>
  <div class="pt-50">
    <b-form-checkbox-group
      v-model="weekdays"
      :options="options"
      switches
      :stacked="isMobile"
    />
  </div>
</template>

<script>
import { BFormCheckboxGroup } from "bootstrap-vue";
export default {
  components: {
    BFormCheckboxGroup,
  },
  data() {
    return {
      weekdays: [],
      options: [
        {
          text: this.shortNames ? this.$t("Dl") : this.$t("Dilluns"),
          value: this.numberValues ? 1 : "MONDAY",
        },
        {
          text: this.shortNames ? this.$t("Dm") : this.$t("Dimarts"),
          value: this.numberValues ? 2 : "TUESDAY",
        },
        {
          text: this.shortNames ? this.$t("Dc") : this.$t("Dimecres"),
          value: this.numberValues ? 3 : "WEDNESDAY",
        },
        {
          text: this.shortNames ? this.$t("Dj") : this.$t("Dijous"),
          value: this.numberValues ? 4 : "THURSDAY",
        },
        {
          text: this.shortNames ? this.$t("Dv") : this.$t("Divendres"),
          value: this.numberValues ? 5 : "FRIDAY",
        },
        {
          text: this.shortNames ? this.$t("Ds") : this.$t("Dissabte"),
          value: this.numberValues ? 6 : "SATURDAY",
        },
        {
          text: this.shortNames ? this.$t("Dg") : this.$t("Diumenge"),
          value: this.numberValues ? 0 : "SUNDAY",
        },
      ],
    };
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    shortNames: {
      type: Boolean,
      default: false,
    },
    numberValues: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters["app/isMobile"];
    },
  },
  created() {
    if (this.value) {
      this.weekdays = this.value;
    }
  },
  watch: {
    weekdays(value) {
      this.$emit("input", value);
    },
  },
};
</script>
