<template>
  <b-card title="Afegeix una nova promoció">
    <!-- FORM -->
    <promotion-form :category="urlCategory" v-model="promotion" class="mb-2" />

    <!-- BUTTONS -->
    <b-row class="d-flex justify-content-center justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="onCancelButtonClicked">
          Cancel·la
        </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="onSaveButtonClicked">
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import PromotionForm from "@/views/promotions/new-promotion/components/PromotionForm.vue";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    PromotionForm,
  },
  data() {
    return {
      promotion: null,
    };
  },
  computed: {
    urlCategory() {
      return this.$route.query?.category || null;
    },
  },
  methods: {
    onCancelButtonClicked() {
      this.$router.push({ name: "foravila-promotions-list" });
    },
    onSaveButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch(`promotions/addPromotion`, this.promotion)
        .then((response) => {
          notifySuccess(
            "Promoció creada",
            "La promoció ha estat creada correctament"
          );
          this.$router.push({
            name: "foravila-promotion-view",
            params: { promotionUuid: response.uuid },
          });
        })
        .catch(() =>
          notifyError(
            "Promoció no creada",
            "Hi ha hagut algun error al intentar crear la promoció, intenta-ho de nou"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
