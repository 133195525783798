<template>
  <b-form>
    <!-- MAIN INFO -->
    <b-row>
      <!-- TITLE -->
      <b-col cols="12" sm="6" lg="6">
        <b-form-group>
          <label for="title" class="mr-25">Títol</label>
          <small class="text-muted">(només per ús intern)</small>
          <b-form-input id="title" v-model="promotion.title" />
        </b-form-group>
      </b-col>

      <!-- CATEGORY -->
      <b-col cols="12" sm="6" lg="6">
        <b-form-group>
          <label for="category">Categoria</label>
          <v-select
            id="category"
            v-model="promotion.category"
            placeholder="Selecciona una categoria de promoció"
            :options="promotionCategoryOptions"
            :reduce="(option) => option.value"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- DETAILS -->
    <template v-if="promotion.category">
      <b-row>
        <b-col cols="12" class="my-1">
          <h5>
            {{ $t("Configuració de la promoció") }}
          </h5>
        </b-col>
      </b-row>

      <b-row v-if="promotion.category === 'PROMO_CODE'">
        <!-- CODE -->
        <b-col cols="12" lg="6">
          <b-form-group>
            <label for="code">Codi promocional</label>
            <b-form-input
              id="code"
              v-model="promotion.code"
              :formatter="codeInputFormatter"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <!-- TYPE -->
        <b-col cols="12" sm="6">
          <b-form-group>
            <label for="type">Tipus</label>
            <v-select
              id="type"
              v-model="promotion.type"
              placeholder="Selecciona una tipus de descompte"
              :options="promotionTypeOptions"
              :reduce="(option) => option.value"
            />
          </b-form-group>
        </b-col>

        <!-- SCOPE -->
        <b-col cols="12" sm="6">
          <b-form-group>
            <label for="scope">Abast</label>
            <v-select
              id="scope"
              v-model="promotion.scope"
              placeholder="Selecciona l'abast del descompte"
              :options="promotionScopeOptions"
              :reduce="(option) => option.value"
              :disabled="scopeInputDisabled"
            />
          </b-form-group>
        </b-col>

        <!-- VALUE -->
        <b-col cols="12" lg="6">
          <b-form-group>
            <label for="value">Valor</label>
            <b-input-group
              id="value"
              :append="valueInputAppendText"
              class="input-group-merge"
            >
              <b-form-input
                type="number"
                v-model="promotion.value"
                autocomplete="off"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- MIN STAY -->
        <b-col cols="12" sm="6">
          <b-form-group>
            <label for="minStay" class="mr-25">Estada mínima</label>
            <small class="text-muted">(opcional)</small>
            <b-input-group
              id="minStay"
              :append="$t('dies')"
              class="input-group-merge"
            >
              <b-form-input
                type="number"
                v-model="promotion.minStay"
                autocomplete="off"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- MAX BOOKINGS -->
        <b-col cols="12" sm="6">
          <b-form-group
            description="Nombre màxim de vegades que aquest descompte podrà ser utilitzat."
          >
            <label for="maxBookings" class="mr-25">Màxim d'usos</label>
            <small class="text-muted">(opcional)</small>
            <b-input-group
              id="maxBookings"
              :append="$t('usos')"
              class="input-group-merge"
            >
              <b-form-input
                type="number"
                v-model="promotion.maxBookings"
                autocomplete="off"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- ADVANCED BOOKING DAYS -->
        <b-col v-if="!advancedBookingDaysInputHidden" cols="12" sm="6">
          <b-form-group :description="advancedBookingDaysInputHelper">
            <label for="advancedBookingDays" class="mr-25">
              {{ advancedBookingDaysInputLabel }}
            </label>
            <small class="text-muted">(opcional)</small>
            <b-input-group
              id="advancedBookingDays"
              :append="$t('dies')"
              class="input-group-merge"
            >
              <b-form-input
                type="number"
                v-model="promotion.advancedBookingDays"
                autocomplete="off"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- CHECKIN DAYS -->
        <b-col cols="12">
          <b-form-group>
            <label for="checkinDays"> Dies d'entrada </label>
            <b-weekdayspicker
              id="checkinDays"
              v-model="promotion.checkinDays"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" class="my-1">
          <h5>
            {{ $t("Períodes de la promoció") }}
          </h5>
        </b-col>
      </b-row>

      <b-row>
        <!-- BOOKING START DATE -->
        <b-col cols="12" lg="6" xl="3">
          <b-form-group>
            <label for="bookingStartDate">
              Data d'inici del període de reserva
            </label>
            <b-datepicker
              id="bookingStartDate"
              v-model="promotion.bookingStartDate"
              :min="new Date()"
            />
          </b-form-group>
        </b-col>

        <!-- BOOKING END DATE -->
        <b-col cols="12" lg="6" xl="3">
          <b-form-group>
            <label for="bookingEndDate">
              Data de fi del període de reserva
            </label>
            <b-datepicker
              id="bookingEndDate"
              v-model="promotion.bookingEndDate"
              :min="new Date()"
            />
          </b-form-group>
        </b-col>

        <!-- STAY START DATE -->
        <b-col cols="12" lg="6" xl="3">
          <b-form-group>
            <label for="stayStartDate"> Data d'inici de l'estada </label>
            <b-datepicker
              id="stayStartDate"
              v-model="promotion.stayStartDate"
              :min="new Date()"
            />
          </b-form-group>
        </b-col>

        <!-- STAY END DATE -->
        <b-col cols="12" lg="6" xl="3">
          <b-form-group>
            <label for="stayEndDate"> Data de fi de l'estada </label>
            <b-datepicker
              id="stayEndDate"
              v-model="promotion.stayEndDate"
              :min="new Date()"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </b-form>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  promotionCategoryOptions,
  promotionTypeOptions,
  promotionScopeOptions,
} from "@/utils/select-options";
import BDatepicker from "@foravila-core/components/b-datepicker/BDatepicker.vue";
import BWeekdayspicker from "@foravila-core/components/b-weekdayspicker/BWeekdayspicker.vue";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BFormGroup,
    vSelect,
    BDatepicker,
    BWeekdayspicker,
  },
  props: {
    value: {
      type: Object,
      defualt: null,
    },
    category: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      promotion: {
        title: null,
        active: true,
        category: null,
        type: null,
        scope: null,
        code: null,
        minStay: null,
        value: null,
        bookingStartDate: null,
        bookingEndDate: null,
        stayStartDate: null,
        stayEndDate: null,
        checkinDays: null,
        maxBookings: null,
        advancedBookingDays: null,
      },
      promotionCategoryOptions,
      promotionTypeOptions,
      promotionScopeOptions,
    };
  },
  watch: {
    "promotion.category"() {
      this.resetPromotion();
    },
    "promotion.type"(type) {
      if (type === "FREE_NIGHTS") this.promotion.scope = null;
    },
    promotion: {
      deep: true,
      handler() {
        this.$emit("input", {
          ...this.promotion,
          minStay: parseInt(this.promotion.minStay),
          value: this.getProcessedValue(this.promotion.value),
          maxBookings: parseInt(this.promotion.maxBookings),
          advancedBookingDays: parseInt(this.promotion.advancedBookingDays),
        });
      },
    },
  },
  mounted() {
    this.initPromotion();
  },
  computed: {
    valueInputAppendText() {
      if (
        !this.promotion.type ||
        (this.promotion.type !== "FREE_NIGHTS" && !this.promotion.scope)
      )
        return null;

      const text = [];

      switch (this.promotion.type) {
        case "PERCENTAGE_DISCOUNT":
          text.push("% de descompte");
          break;
        case "AMOUNT_DISCOUNT":
          text.push("€ de descompte");
          break;
        case "FIXED_PRICE":
          text.push("€ de preu fixe");
          break;
        case "FREE_NIGHTS":
          text.push("nits gratuïtes");
          break;
        default:
          break;
      }

      if (this.promotion.scope) {
        switch (this.promotion.scope) {
          case "BOOKING":
            text.push("per reserva");
            break;
          case "WEEK":
            text.push("per setmana");
            break;
          case "NIGHT":
            text.push("per nit");
            break;
          case "BOOKING_GUEST":
            text.push("per reserva i hoste");
            break;
          case "WEEK_GUEST":
            text.push("per setmana i hoste");
            break;
          case "NIGHT_GUEST":
            text.push("per nit i hoste");
            break;
          default:
            break;
        }
      }

      return text.join(" ");
    },
    valueInputDisabled() {
      return (
        !this.promotion.type ||
        (this.promotion.type !== "FREE_NIGHTS" && !this.promotion.scope)
      );
    },
    scopeInputDisabled() {
      return this.promotion.type === "FREE_NIGHTS";
    },
    advancedBookingDaysInputHidden() {
      return ["STANDARD", "PROMO_CODE"].includes(this.promotion.category);
    },
    advancedBookingDaysInputLabel() {
      if (this.promotion.category === "LAST_MINUTE")
        return "Antel·lació màxima";
      if (this.promotion.category === "EARLY_BOOKING")
        return "Antel·lació mínima";
      return null;
    },
    advancedBookingDaysInputHelper() {
      if (this.promotion.category === "LAST_MINUTE")
        return "Màxim de dies d'antel·lació en que s'ha de fer la reserva per poder aplicar el descompte.";
      if (this.promotion.category === "EARLY_BOOKING")
        return "Mínim de dies d'antel·lació en que s'ha de fer la reserva per poder aplicar el descompte.";
      return null;
    },
  },
  methods: {
    initPromotion() {
      this.promotion.title = this.value?.title || null;
      this.promotion.active = this.value?.active || true;
      this.promotion.category = this.getInitialPromotionCategory();
      this.promotion.type = this.value?.type || null;
      this.promotion.scope = this.value?.scope || null;
      this.promotion.code = this.value?.code || null;
      this.promotion.minStay = this.value?.minStay || null;
      this.promotion.value = this.value?.value || null;
      this.promotion.bookingStartDate = this.value?.bookingStartDate || null;
      this.promotion.bookingEndDate = this.value?.bookingEndDate || null;
      this.promotion.stayStartDate = this.value?.stayStartDate || null;
      this.promotion.stayEndDate = this.value?.stayEndDate || null;
      this.promotion.checkinDays = this.value?.checkinDays || [
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY",
      ];
      this.promotion.maxBookings = this.value?.maxBookings || null;
      this.promotion.advancedBookingDays =
        this.value?.advancedBookingDays || null;
    },
    resetPromotion() {
      this.promotion.active = true;
      this.promotion.type = null;
      this.promotion.scope = null;
      this.promotion.code = null;
      this.promotion.minStay = null;
      this.promotion.value = null;
      this.promotion.bookingStartDate = null;
      this.promotion.bookingEndDate = null;
      this.promotion.stayStartDate = null;
      this.promotion.stayEndDate = null;
      this.promotion.checkinDays = [
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY",
      ];
      this.promotion.maxBookings = null;
      this.promotion.advancedBookingDays = null;
    },
    getInitialPromotionCategory() {
      if (!this.category) return this.value?.category || null;
      return this.category === "promo-code" ? "PROMO_CODE" : "STANDARD";
    },
    codeInputFormatter(code) {
      return code.replace(" ", "_").toUpperCase();
    },
    getProcessedValue(value) {
      if (!this.promotion?.type) return parseInt(value);
      switch (this.promotion.type) {
        case "AMOUNT_DISCOUNT":
        case "FIXED_PRICE":
          return parseInt(value) * 100;
        case "PERCENTAGE_DISCOUNT":
        case "FREE_NIGHTS":
        default:
          return parseInt(value);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
